// Copyright 2024 Immersive Technologies Pty Ltd. All rights reserved.
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  ThemeProvider,
  CssBaseline,
  Alert,
} from '@mui/material';
import LoadingPage from '../Common/LoadingPage';
import { t } from '../../i18n/i18n';
import { FormEvent, useEffect, useState } from 'react';
import { ErrorCode, getErrorCode, getErrorMessage } from '../../utils/errorUtils';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { AlertExpiredPage } from './ResetPwdPages/AlertExpiredPage';
import { useSearchParams } from 'react-router-dom';
import { resetPassword, resetPasswordForNewUser, validateResetCode } from '../../utils/authServiceUtils';
import { resetPwdTheme } from '../Themes/Themes';
import Logo from '../Logo';
import { validatePasswordLength } from '../../utils/passwordUtils';

interface IResetPwdState {
  errorCode: ErrorCode | null;
  isLoading: boolean;
  isValid: boolean;
  isChanging: boolean;
  newPassword: string;
  confirmPassword: string;
  showPassword: boolean;
  showConfirmPassword: boolean;
}

// Interface for handling errors specific to each field in the reset password form.
// Each property corresponds to an error that might occur in the respective section:
// - newPasswordError: Error related to the new password (e.g., password too short).
// - confirmPasswordError: Error related to the confirmation of the new password (e.g., passwords do not match).
interface IError {
  newPasswordError: ErrorCode | null;
  confirmPasswordError: ErrorCode | null;
}

export const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const tokenId = searchParams.get('id');
  const redirectTo = searchParams.get('redirectTo');
  const code = searchParams.get('code');
  const pwd = searchParams.get('pwd');
  const username = searchParams.get('username');

  // For this reset password page, we use it for two purposes:
  // 1. Reset password for new users where they have to log in first to reset the temporary password.
  // 2. Reset password for existing users.
  // New users are provided with a temporary password, while existing users receive a reset code.
  const hasRequiredTokenAndRedirect = tokenId && redirectTo;
  const isExistingUserReset = code && hasRequiredTokenAndRedirect;
  const isNewUserReset = pwd && username && hasRequiredTokenAndRedirect;

  const [resetPwdState, setResetPwdState] = useState<IResetPwdState>({
    errorCode: null,
    isLoading: true, // Indicates whether the page is loading
    isValid: false, // Indicates whether the reset token is valid
    isChanging: false, // Indicates whether the password is currently being changed
    newPassword: '',
    confirmPassword: '',
    showPassword: false, // Toggle the password visibility
    showConfirmPassword: false,
  });

  const [errors, setErrors] = useState<IError>({
    newPasswordError: null,
    confirmPasswordError: null,
  });

  // Check that the recovery token contained in the link is valid and not expired
  const validateLink = async () => {
    try {
     const isValid = isExistingUserReset || isNewUserReset ? await validateResetCode(tokenId) : false;
     setResetPwdState({ ...resetPwdState, isValid, isLoading: false });
    } catch (err) {
      setResetPwdState({ ...resetPwdState, isValid: false, isLoading: false });
    }
  };

  const handleTogglePasswordVisibility = (field) => {
    setResetPwdState((prevState) => ({ ...prevState, [field]: !prevState[field] }));
  };

  const handleChange = (prop) => (event) => {
    // Reset the error code when the user types into the password fields
    setResetPwdState((prevState) => {
      const newState = { ...prevState, [prop]: event.target.value, errorCode: null };
      validatePasswords(newState);
      return newState;
    });
  };

  const validatePasswords = (currentState) => {
    let newPasswordError: ErrorCode | null = null;
    let confirmPasswordError: ErrorCode | null = null;

    // If the new password does not meet the minimum requirements, show an error
    if (!validatePasswordLength(currentState.newPassword)) {
      newPasswordError = ErrorCode.MinimumPasswordLength;
    }

    // Only show this error if both fields have been typed into
    if (currentState.confirmPassword.length > 0 && currentState.newPassword !== currentState.confirmPassword) {
      confirmPasswordError = ErrorCode.TwoNewPasswordNotMatch;
    }

    setErrors({
      newPasswordError,
      confirmPasswordError,
    });
  };

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (errors.newPasswordError || errors.confirmPasswordError) {
      return;
    }

    setResetPwdState({ ...resetPwdState, isLoading: true });

    try {
      // Determine which endpoint to call based on the reset type
      if (isExistingUserReset) {
        // Call the endpoint for resetting password for existing users
        await resetPassword(tokenId, code, resetPwdState.newPassword);
      } else if (isNewUserReset) {
        // Call the endpoint for resetting password for new users
        await resetPasswordForNewUser(tokenId, username, pwd, redirectTo, resetPwdState.newPassword);
      } else {
        throw new Error('Missing required parameters.');
      }

      // Redirect back to product login after successfully setting new password
      window.location.href = redirectTo;
    } catch (err) {
      const errorCode = getErrorCode(err);
      // Log unhandled errors
      if (errorCode === ErrorCode.Unknown) {
        console.error(`Failed to reset password for ${window.location.href}:  ${(err as Error).message} `);
      }
      setResetPwdState({ ...resetPwdState, isLoading: false, errorCode });
    }
  };

  useEffect(() => {
    // Validate the recovery link
    validateLink();
  }, []); // eslint-disable-line

  return (
    <ThemeProvider theme={resetPwdTheme}>
      <CssBaseline />
      <Dialog open>
        {resetPwdState.isLoading ? (
          <LoadingPage />
        ) : (
          <Box>
            <Logo />
            <DialogTitle id="form-dialog-title">
              {t({ defaultMessage: 'Reset your password', id: 'p+kmJg' })}
            </DialogTitle>
            <DialogContent>
              {resetPwdState.isValid ? (
                <Box id="set-new-password">
                  {resetPwdState.errorCode && (
                    <Alert severity="error">{getErrorMessage(resetPwdState.errorCode)}</Alert>
                  )}
                  <TextField
                    id="new-password"
                    variant="outlined"
                    autoFocus
                    margin="dense"
                    placeholder={t({
                      defaultMessage: 'Enter your new password',
                      id: 'h/zqNr',
                    })}
                    type={resetPwdState.showPassword ? 'text' : 'password'}
                    autoComplete="new-password"
                    fullWidth
                    error={!!errors.newPasswordError}
                    helperText={errors.newPasswordError && getErrorMessage(errors.newPasswordError)}
                    value={resetPwdState.newPassword}
                    onChange={handleChange('newPassword')}
                    onKeyDown={(ev) => {
                      if (ev.key === 'Enter') {
                        onSubmit(ev);
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => handleTogglePasswordVisibility('showPassword')}
                            edge="end">
                            {resetPwdState.showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    margin="dense"
                    placeholder={t({
                      defaultMessage: 'Re-enter your new password to confirm',
                      id: 'FHsQzt',
                    })}
                    type={resetPwdState.showConfirmPassword ? 'text' : 'password'}
                    fullWidth
                    variant="outlined"
                    value={resetPwdState.confirmPassword}
                    onChange={handleChange('confirmPassword')}
                    error={!!errors.confirmPasswordError}
                    helperText={errors.confirmPasswordError && getErrorMessage(errors.confirmPasswordError)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => handleTogglePasswordVisibility('showConfirmPassword')}
                            edge="end">
                            {resetPwdState.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Button
                    className="reset-password"
                    fullWidth
                    disableElevation
                    variant={'contained'}
                    color="primary"
                    disabled={resetPwdState.confirmPassword.length === 0 || !!errors.newPasswordError || !!errors.confirmPasswordError}
                    onClick={onSubmit}>
                    {!resetPwdState.isChanging ? (
                      t({
                        defaultMessage: 'Submit',
                        id: 'wSZR47',
                      })
                    ) : (
                      <CircularProgress color="info" size={24} />
                    )}
                  </Button>
                </Box>
              ) : (
                // Display an error message if the recovery link is invalid
                <AlertExpiredPage isNewUserReset={!!isNewUserReset} />
              )}
            </DialogContent>
          </Box>
        )}
      </Dialog>
    </ThemeProvider>
  );
};
